<template>
  <div class="container">
    <nav-box title="先付模式" :back="true"></nav-box>
    <div class="content flex-column">
      <div class="tab flex">
        <div
          class="tab-item flex flex-center"
          :class="active == 1 ? 'on' : ''"
          @click="changeTab(1)"
        >
          自助开牌
        </div>
        <div
          class="tab-item flex flex-center"
          :class="active == 2 ? 'on' : ''"
          @click="changeTab(2)"
        >
          手工开牌
        </div>
      </div>
      <div v-show="active == 1" class="flex">
        <div class="code-box flex-column flex-center">
          <div class="code">
            <img :src="imageUrl" alt="" />
          </div>
          <p class="tip">请使用“ 微信 ”扫描上方二维码</p>
        </div>
      </div>
      <div v-show="active == 2" class="flex" style="width: 100%">
        <div class="guest-box flex-column">
          <p class="title">请选择人数</p>
          <div class="guest-sex flex-column">
            <div class="sex-item flex flex-sb">
              <div class="sex flex flex-center">
                <img src="@/assets/man.png" alt="" sizes="" srcset="" />
                <span>男宾</span>
              </div>
              <div class="num flex flex-center">
                <div class="minus flex" @click="minusNum('manNumber')">
                  <img
                    src="@/assets/minus-icon.png"
                    v-if="manNumber > 0"
                    alt=""
                  />
                  <img src="@/assets/minus-icon-disable.png" v-else alt="" />
                </div>
                <div class="number">
                  {{ manNumber }}
                </div>
                <div class="add flex" @click="addNum('manNumber')">
                  <img src="@/assets/add-icon.png" alt="" />
                </div>
              </div>
            </div>
            <div class="sex-item flex flex-sb">
              <div class="sex flex flex-center">
                <img src="@/assets/woman.png" alt="" sizes="" srcset="" />
                <span>女宾</span>
              </div>
              <div class="num flex flex-center">
                <div class="minus flex" @click="minusNum('womanNumber')">
                  <img
                    src="@/assets/minus-icon.png"
                    v-if="womanNumber > 0"
                    alt=""
                  />
                  <img src="@/assets/minus-icon-disable.png" v-else alt="" />
                </div>
                <div class="number">
                  {{ womanNumber }}
                </div>
                <div class="add flex" @click="addNum('womanNumber')">
                  <img src="@/assets/add-icon.png" alt="" />
                </div>
              </div>
            </div>
          </div>
          <p class="title">请选择客人类型</p>
          <div class="guest-type flex-column">
            <select v-model="guestTypeId" name="" id="">
              <option value="">请选择</option>
              <option
                :label="item.name"
                :value="item.id"
                v-for="item in guestTypeList"
                :key="item.id"
              ></option>
            </select>
          </div>
          <div class="btn-group flex flex-sb">
            <div class="btn-item reset flex flex-center" @click="reset">
              重置
            </div>
            <div class="btn-item affirm flex flex-center" @click="affirm">
              确认
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import navBox from "@/components/nav-box.vue";
export default {
  components: {
    navBox,
  },
  name: "prepayment",
  data() {
    return {
      active: 2,
      imageUrl: "",
      guestTypeList: [],
      manNumber: 0,
      womanNumber: 0,
      guestTypeId: "",
      timer: null,
    };
  },
  created() {
    if (this.active == 1) {
      this.getCode();
      this.setTime();
    }
    this.getGuestType();
  },
  beforeDestroy() {
    clearInterval(this.timer); // 清除定时器
    this.timer = null;
  },
  methods: {
    changeTab(index) {
      this.active = index;
      if (this.active == 1) {
        this.getCode();
        this.setTime();
      } else {
        clearInterval(this.timer); // 清除定时器
        this.timer = null;
      }
      this.reset();
    },
    setTime() {
      //每隔一分钟运行一次保存方法
      this.timer = setInterval(() => {
        this.getCode();
      }, 60000);
    },
    // 重置
    reset() {
      this.manNumber = 0;
      this.womanNumber = 0;
      this.guestTypeId = "";
    },
    // 确定
    affirm() {
      console.log(this.guestTypeId);
      if (this.manNumber == 0 && this.womanNumber == 0) {
        this.$toast("请至少添加一个男宾或者女宾");
        return false;
      }
      if (this.guestTypeId == "") {
        this.$toast("请选择客人类型");
        return false;
      }
      let data = {
        manQuantity: this.manNumber,
        femaleQuantity: this.womanNumber,
        guestTypeId: this.guestTypeId,
        consumptionAreaId: localStorage.getItem("consumptionAreaId"),
      };
      this.$post(this.Api.ManualOpen, data).then((res) => {
        if (res.data.length > 0) {
          this.reset();
          localStorage.setItem("keyCard", res.data[0]);
          this.$router.push("/list");
        }
      });
    },
    // 获取小程序二维码
    getCode() {
      let data = {
        page: "pages/index/bindCard/bindCard",
        // page: "pages/index/index",
        expiry: 12,
        check_Path: true,
        isHyaline: true,
        width: 546,
        data:{},
        envVersion: "release", //envVersion:  要打开的小程序版本。正式版为 release，体验版为 trial，开发版为 develop，默认 release
      };
      this.$post(this.Api.qrcode, data, { responseType: "blob" }).then(
        (res) => {
          let blob = new Blob([res]);
          let url = window.URL.createObjectURL(blob);
          this.imageUrl = url;
        }
      );
    },
    // 获取客人类型列表
    getGuestType() {
      this.$fetchNo(this.Api.GuestType, { status: 1 }).then((res) => {
        const guestTypeList = []
        res.data.items.forEach(item=>{
          if(item.terminals && item.terminals.find(e=>e.terminalId==9)){
            guestTypeList.push(item)
          }
        })
        this.guestTypeList = res.data.items;
      });
    },
    // 数量减法
    minusNum(item) {
      if (item == "manNumber") {
        if (this.manNumber == 0) {
          this.manNumber = 0;
        } else {
          this.manNumber--;
        }
      } else {
        if (this.womanNumber == 0) {
          this.womanNumber = 0;
        } else {
          this.womanNumber--;
        }
      }
    },
    // 数量加法
    addNum(item) {
      if (item == "manNumber") {
        this.manNumber++;
      } else {
        this.womanNumber++;
      }
    },
  },
};
</script>

<style scoped lang="less">
.content {
  align-items: center;
  justify-content: center;
  padding-top: 180px;
  .tab {
    .tab-item {
      width: 341px;
      height: 105px;
      box-sizing: border-box;
      border: 4px solid #dadde1;
      border-radius: 15px;
      font-size: 44px;
      font-weight: 500;
      color: rgba(10, 25, 41, 0.6);
      &:first-child {
        border-right: 0;
        border-radius: 15px 0 0 15px;
      }
      &:nth-child(2) {
        border-left: 0;
        border-radius: 0 15px 15px 0;
      }
      &.on {
        background-color: #155bd4;
        border-color: #155bd4;
        color: #fff;
      }
    }
  }
  .code-box {
    width: 850px;
    height: 850px;
    border-radius: 24px;
    background-color: #ecf5ff;
    margin-top: 140px;
    .code {
      width: 546px;
      height: 546px;
      background-color: #fff;
      margin-bottom: 90px;
      border-radius: 8px;
      overflow: hidden;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .tip {
      font-size: 40px;
      color: #363d45;
      font-weight: 500;
    }
  }
  .guest-box {
    margin-top: 104px;
    width: 100%;
    .title {
      font-size: 40px;
      color: #1a1e22;
      margin-bottom: 32px;
      line-height: 50px;
      font-weight: 700;
      text-align: center;
    }
    .guest-sex {
      box-sizing: border-box;
      padding: 0 220px;
      margin-bottom: 45px;
      .sex-item {
        margin-bottom: 75px;
        .sex {
          img {
            width: 120px;
            height: 120px;
            margin-right: 16px;
          }
          span {
            font-size: 40px;
            color: #1a1e22;
            line-height: 50px;
          }
        }
        .num {
          img {
            width: 80px;
            height: 80px;
          }
          .number {
            font-size: 44px;
            color: #333333;
            font-weight: 700;
            width: 100px;
            line-height: 80px;
            text-align: center;
          }
        }
      }
    }
    .guest-type {
      width: 100%;
      box-sizing: border-box;
      padding: 0 220px;
      margin-bottom: 165px;
      select {
        width: 100%;
        height: 140px;
        background: #f4f4f4;
        box-shadow: inset 2px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 16px;
        font-size: 44px;
        text-indent: 32px;
        color: #1a1e22;
        border: 0;
        &::placeholder {
          color: #b9bdc6;
        }
      }
    }
    .btn-group {
      width: 100%;
      box-sizing: border-box;
      padding: 0 105px;
      .btn-item {
        width: 392px;
        height: 140px;
        border-radius: 16px;
        background-color: #f4f4f4;
        font-size: 40px;
        color: #1a1e22;
        font-weight: 700;
        &.affirm {
          color: #fff;
          background-color: #155bd4;
        }
      }
    }
  }
}
</style>